import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics, isSupported } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDIJjPyCsVU5ANzvXY5oq5PGZfKP2g6H2c',
  authDomain: 'daughertydatatrust.firebaseapp.com',
  projectId: 'daughertydatatrust',
  storageBucket: 'daughertydatatrust.appspot.com',
  messagingSenderId: '362503254201',
  appId: '1:362503254201:web:43fcda4ba5f856799aab68',
  measurementId: 'G-CRR0XGNXQ5',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const analytics = async () =>
  (await isSupported()) ? getAnalytics(app) : null

export default app
