// Material UI
import { Grid } from '@mui/material'

// Styles
import useStyles from './styles'

// Router
import { Outlet } from 'react-router-dom'

// Components
import { AppNavbar } from 'components/Navbars'
import { AppFooter } from 'components/Footers'

const App = () => {
  // Styles
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <AppNavbar />
      </Grid>
      <Grid item xs={12} className={classes.outletGrid}>
        <Outlet />
      </Grid>
      <Grid item xs={12}>
        <AppFooter />
      </Grid>
    </Grid>
  )
}

export default App
