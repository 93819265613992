import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.neutral,
  },
  formTitleGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formInputGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signInButtonAndForgotPasswordGrid: {
    borderSpacing: 10,
    paddingTop: '2%',
  },
  signInButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  microsoftSignInButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    width: '75%',
  },
  button: {
    width: '50%',
  },
}))

export default useStyles
