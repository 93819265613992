// Pages
import { Company, Dashboard, ManageData } from 'pages/app'
import {
  ForgotPassword,
  Login,
  ForgotPasswordEmailSent,
  Register,
} from 'pages/auth'

// Layouts
import { App, Auth } from 'layouts'

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard'
import CloudCircleIcon from '@mui/icons-material/CloudCircle'

// Router
import PrivateOutlet from 'routes/PrivateOutlet'
import { Navigate } from 'react-router-dom'

const routesObject = [
  {
    path: '/app',
    element: <PrivateOutlet />,
    children: [
      {
        element: <App />,
        children: [
          {
            path: '/app/company/:company',
            element: <Company />,
            name: 'Company',
            drawer: false,
          },
          {
            path: '/app/dashboard',
            element: <Dashboard />,
            name: 'Dashboard',
            icon: <DashboardIcon />,
            drawer: true,
          },
          {
            path: '/app/managedata',
            element: <ManageData />,
            name: 'Manage Data',
            icon: <CloudCircleIcon />,
            drawer: true,
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    element: <Auth />,
    children: [
      {
        path: '/auth/login',
        element: <Login />,
      },
      {
        path: '/auth/forgotpassword',
        element: <ForgotPassword />,
      },
      {
        path: '/auth/forgotpasswordemailsent',
        element: <ForgotPasswordEmailSent />,
      },
      {
        path: '/auth/register',
        element: <Register />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/auth/login" replace />,
  },
]

export default routesObject
