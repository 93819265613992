//React
import { PropTypes } from 'prop-types'

// Material
import { Snackbar, Alert as MuiAlert } from '@mui/material'

const Alert = ({ open, severity, message, onClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <MuiAlert onClose={onClose} severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert

Alert.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
}
