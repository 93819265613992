import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Card, Grid, Typography, Link } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    minHeight: '10vw',
  },
  cardTitle: {
    fontSize: '1.75rem',
    fontWeight: '500',
  },
}))

const CompanyCard = ({ companyName }) => {
  const classes = useStyles()

  return (
    <Grid item xs={3} className={classes.root}>
      <Link component={RouterLink} to={`/app/company/${companyName}`}>
        <Card key={companyName} raised={true} className={classes.card}>
          <Typography variant="h2" className={classes.cardTitle}>
            {companyName}
          </Typography>
        </Card>
      </Link>
    </Grid>
  )
}

CompanyCard.propTypes = {
  companyName: PropTypes.string,
}

export default CompanyCard
