//React
import { PropTypes } from 'prop-types'

// Material
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material'

// Routing
import { useNavigate } from 'react-router-dom'
import routes from 'routes'

const AppDrawer = ({ open, onClose, logo }) => {
  const navigate = useNavigate()

  const getLinks = (routes) => {
    const children = routes.find((route) => route.path === '/app').children[0]
      .children

    const drawerChildren = children.filter((route) => route.drawer)

    const handleItemClick = (route) => {
      navigate(route.path)
      onClose()
    }

    return drawerChildren.map((route, key) => {
      return (
        <ListItem onClick={() => handleItemClick(route)} button key={key}>
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText>{route.name}</ListItemText>
        </ListItem>
      )
    })
  }

  return (
    <Drawer open={open} onClose={onClose}>
      <List style={{ padding: '1rem' }}>
        <img src={logo} alt="Data Trust Logo" width="200rem" />
        <Divider />
        {getLinks(routes)}
      </List>
    </Drawer>
  )
}

export default AppDrawer

AppDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  logo: PropTypes.string,
}
