//React
import { useState } from 'react'

// Material UI
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

// Styles
import useStyles from './styles'

// Routing
import { useNavigate } from 'react-router'

//Firebase
import { passwordReset } from 'assets/plugins/firebase/auth'

// Components
import Alert from 'components/Alert'

// Yup
import { object as yupObject, string as yupString } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

// Hook form
import { useForm, Controller } from 'react-hook-form'

const formSchema = yupObject().shape({
  email: yupString().required('Email is Required').email(),
})

const defaultEmail = {
  email: '',
}

const ForgotPassword = () => {
  // Styles
  const classes = useStyles()

  // Routing
  const navigate = useNavigate()

  // State
  const [BadResetState, setBadResetState] = useState(false)
  const [ErrorMessageState, setErrorMessageState] = useState({ message: '' })
  const [LoadingState, setLoadingState] = useState(false)

  // Hook form
  const { handleSubmit, control } = useForm({
    defaultValues: defaultEmail,
    resolver: yupResolver(formSchema),
  })

  // Handlers
  const handleResetClick = async (data) => {
    setLoadingState(true)
    try {
      await passwordReset(data.email)
      setLoadingState(false)
      navigate('/auth/forgotpasswordemailsent')
    } catch (error) {
      setBadResetState(true)
      setErrorMessageState(error)
      setLoadingState(false)
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit((data) => handleResetClick(data))
    }
  }

  return (
    <Box
      className={classes.root}
      component="form"
      onSubmit={handleSubmit((data) => handleResetClick(data))}
    >
      {/* Forgot Password form */}
      <Grid container spacing={4}>
        {/* Form title */}
        <Grid item xs={12}>
          <Typography variant="h3">Forgot your password?</Typography>
          <Typography variant="body1">Enter your email.</Typography>
        </Grid>
        {/* Email */}
        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl className={classes.textField}>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="email"
                  placeholder="Email"
                  label="Email"
                  variant="filled"
                />
              </FormControl>
            )}
            name="email"
            control={control}
          />
        </Grid>
      </Grid>
      {/* Send and cancel button */}
      <Grid container spacing={1} className={classes.sendAndCancelButtonsGrid}>
        <Grid item xs={12} sm={3} md={2}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => navigate('/auth/login')}
            disabled={LoadingState}
            className={classes.button}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={LoadingState}
            className={classes.button}
          >
            Send Email
          </Button>
        </Grid>
      </Grid>
      <Alert
        open={BadResetState}
        onClose={() => setBadResetState(false)}
        message={ErrorMessageState.message}
        severity="error"
      />
    </Box>
  )
}

export default ForgotPassword
