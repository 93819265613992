// React
import { useState } from 'react'

// Material UI
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'

// Styles
import useStyles from './styles'

// Routing
import { useNavigate } from 'react-router-dom'

// Components
import Alert from 'components/Alert'

// Yup
import { object as yupObject, string as yupString } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

// Hook form
import { useForm, Controller } from 'react-hook-form'

// Constants
import {
  confirmPasswordRules,
  passwordRules,
  phoneRules,
} from 'assets/constants'

// Firebase
import { userRegister } from 'assets/plugins/firebase/auth'

const formSchema = yupObject().shape({
  firstName: yupString().required('First Name is Required'),
  lastName: yupString().required('Last Name is Required'),
  email: yupString().required('Email is Required').email(),
  phone: phoneRules,
  password: passwordRules,
  confirmPassword: confirmPasswordRules,
  role: yupString().required('Role is Required'),
})

const defaultFormUser = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  role: '',
}

const Register = () => {
  // Styles
  const classes = useStyles()

  // Routing
  const navigate = useNavigate()

  // State
  const [BadRegisterState, setBadRegisterState] = useState(false)
  const [LoadingState, setLoadingState] = useState(false)
  const [ErrorMessageState, setErrorMessageState] = useState({ message: '' })

  // Hook form
  const { handleSubmit, control } = useForm({
    defaultValues: defaultFormUser,
    resolver: yupResolver(formSchema),
  })

  // Handlers
  const handleRegisterClick = async (data) => {
    setLoadingState(true)
    try {
      const user = await userRegister(data.email, data.password)
      //TODO: Add user to database
      console.log(user)
      navigate('/app/login')
    } catch (error) {
      setLoadingState(false)
      setErrorMessageState(error)
      setBadRegisterState(true)
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit((data) => handleRegisterClick(data))
    }
  }

  return (
    <Box
      className={classes.root}
      component="form"
      onSubmit={handleSubmit((data) => handleRegisterClick(data))}
    >
      {/* Register form */}
      <Grid container spacing={2}>
        {/* Form title */}
        <Grid item xs={12} className={classes.formTitleGrid}>
          <Typography variant="h2">Register</Typography>
        </Grid>
        {/* First name */}
        <Grid item xs={12} md={6} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                  type="text"
                  placeholder="First name"
                  label="First name"
                  variant="filled"
                />
              </FormControl>
            )}
            name="firstName"
            control={control}
          />
        </Grid>
        {/* Last name */}
        <Grid item xs={12} md={6} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                  type="text"
                  placeholder="Last name"
                  label="Last name"
                  variant="filled"
                />
              </FormControl>
            )}
            name="lastName"
            control={control}
          />
        </Grid>
        {/* Email */}
        <Grid item xs={12} md={6} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="email"
                  placeholder="email@email.com"
                  label="Email"
                  variant="filled"
                />
              </FormControl>
            )}
            name="email"
            control={control}
          />
        </Grid>
        {/* Phone */}
        <Grid item xs={12} md={6} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="text"
                  placeholder="(XXX-XXX-XXXX)"
                  label="Phone"
                  variant="filled"
                />
              </FormControl>
            )}
            name="phone"
            control={control}
          />
        </Grid>
        {/* Password */}
        <Grid item xs={12} md={6} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl className={classes.textField}>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="password"
                  placeholder="Password"
                  label="Password"
                  variant="filled"
                />
              </FormControl>
            )}
            name="password"
            control={control}
          />
        </Grid>
        {/* Password Confirmation */}
        <Grid item xs={12} md={6} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl className={classes.textField}>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="password"
                  placeholder="Password"
                  label="Retype Password"
                  variant="filled"
                />
              </FormControl>
            )}
            name="confirmPassword"
            control={control}
          />
        </Grid>
        {/* Role */}
        <Grid item xs={12} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl error={fieldState.error !== undefined}>
                <FormLabel>Role</FormLabel>
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="provider"
                    control={<Radio />}
                    label="Provider"
                  />
                  <FormControlLabel
                    value="consumer"
                    control={<Radio />}
                    label="Consumer"
                  />
                  <FormControlLabel
                    value="both"
                    control={<Radio />}
                    label="Both"
                  />
                </RadioGroup>
                <FormHelperText>
                  {fieldState.error?.message || ' '}
                </FormHelperText>
              </FormControl>
            )}
            name="role"
            control={control}
          />
        </Grid>
        {/* Register button */}
        <Grid item xs={12} className={classes.registerButtonGrid}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={LoadingState}
            className={classes.button}
          >
            Register
          </Button>
        </Grid>
      </Grid>
      <Alert
        open={BadRegisterState}
        onClose={() => setBadRegisterState(false)}
        message={ErrorMessageState.message}
        severity="error"
      />
    </Box>
  )
}

export default Register
