// React
import React, { StrictMode } from 'react'
import { render } from 'react-dom'

// Router
import { BrowserRouter } from 'react-router-dom'

//Theme
import theme from 'theme'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

//Pages
import Router from 'routes/Router'

// User
import { UserProvider } from 'assets/plugins/contexts/userContext'

// React Query
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

render(
  <StrictMode>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </CssBaseline>
        </ThemeProvider>
      </QueryClientProvider>
    </UserProvider>
  </StrictMode>,
  document.getElementById('root')
)
