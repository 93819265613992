// User
import { useUser } from 'assets/hooks'

// Router
import { Outlet, Navigate } from 'react-router-dom'

const PrivateOutlet = () => {
  const user = useUser()
  return user ? <Outlet /> : <Navigate to="/auth/login" />
}

export default PrivateOutlet
