import { Icon } from '@mui/material'

const microsfotSvg = require('assets/images/icons/microsoft.svg').default

const MicrosfotIcon = (props) => (
  <Icon {...props}>
    <img src={microsfotSvg} />
  </Icon>
)

export { MicrosfotIcon }
