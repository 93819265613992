// Material UI
import { Box, Button, Grid, Typography } from '@mui/material'

// Styles
import useStyles from './styles'

// Routing
import { useNavigate } from 'react-router'

const ForgotPasswordEmailSent = () => {
  // Styles
  const classes = useStyles()

  // Routing
  const navigate = useNavigate()

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3">Hooray! Email sent</Typography>
          <Typography variant="body1">
            Check your inbox for further instructionss.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => navigate('/auth/login')}
            className={classes.button}
          >
            SIGN IN
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ForgotPasswordEmailSent
