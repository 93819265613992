//React
import React from 'react'

// Material
import { Box } from '@mui/material'

// Router
import { useRoutes } from 'react-router-dom'
import routesObject from 'routes'

const Router = () => {
  const routes = useRoutes(routesObject)

  return <Box>{routes}</Box>
}

export default Router
