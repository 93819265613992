// React
import { useState } from 'react'

// Material
import { AppBar, Avatar, Button, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

// Components
import { AppDrawer } from 'components/Drawers'
import { AppMenu } from 'components/Menus'

// Hooks
import useUser from 'assets/hooks/useUser'

const AppNavbar = () => {
  const [DrawerState, setDrawerState] = useState(false)
  const [AnchorState, setAnchorState] = useState(null)
  const [MenuState, setMenuState] = useState(false)

  const user = useUser()

  const logo = require('assets/images/logo.svg').default

  const handleMenuClose = () => {
    setMenuState(false)
    setAnchorState(null)
  }

  const handleMenuOpen = (event) => {
    setAnchorState(event.currentTarget)
    setMenuState(true)
  }

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton onClick={() => setDrawerState(true)}>
          <MenuIcon color="white" />
        </IconButton>
        <Button
          onClick={handleMenuOpen}
          endIcon={<Avatar alt="Avatar" src={user?.photoURL} />}
        >
          {user?.displayName}
        </Button>
      </Toolbar>
      <AppMenu
        anchor={AnchorState}
        open={MenuState}
        onClose={handleMenuClose}
      />
      <AppDrawer
        logo={logo}
        open={DrawerState}
        onClose={() => setDrawerState(false)}
      />
    </AppBar>
  )
}

export default AppNavbar
