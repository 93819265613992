// Material
import { Box, Grid, Paper } from '@mui/material'

// Styles
import useStyles from './styles'

// Router
import { Outlet } from 'react-router-dom'

const logo = require('assets/images/logo.svg').default

const Auth = () => {
  // Styles
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} className={classes.authGrid}>
        <Grid item xs={12} md={7}>
          <Paper elevation={0} className={classes.paperLogo}>
            <img src={logo} alt="Data Trust Logo" className={classes.logo} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Auth
