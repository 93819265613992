// React
import { useState } from 'react'

// Material UI
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

// Styles
import useStyles from './styles'

// Routing
import { Link, useNavigate } from 'react-router-dom'

// Firebase
import { emailLogin, signInWithAD } from 'assets/plugins/firebase/auth'

// Components
import Alert from 'components/Alert'
import { MicrosfotIcon } from 'components/Icons'

// Yup
import { object as yupObject, string as yupString } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

// Hook form
import { useForm, Controller } from 'react-hook-form'

const formSchema = yupObject().shape({
  email: yupString().required('Email is Required').email(),
  password: yupString().required('Password is Required'),
})

const defaultUser = {
  email: '',
  password: '',
}

const Login = () => {
  // Styles
  const classes = useStyles()

  // Routing
  const navigate = useNavigate()

  // State
  const [BadLoginState, setBadLoginState] = useState(false)
  const [LoadingState, setLoadingState] = useState(false)
  const [ErrorMessageState, setErrorMessageState] = useState({ message: '' })

  // Hook form
  const { handleSubmit, control } = useForm({
    defaultValues: defaultUser,
    resolver: yupResolver(formSchema),
  })

  // Handlers
  const handleLoginClick = async (data) => {
    setLoadingState(true)
    try {
      const { user } = await emailLogin(data.email, data.password)
      await user.getIdTokenResult()
      navigate('/app/dashboard')
    } catch (error) {
      setLoadingState(false)
      setErrorMessageState(error)
      setBadLoginState(true)
    }
  }

  const handleMicrosoftLoginClick = async () => {
    setLoadingState(true)
    try {
      const { user } = await signInWithAD()
      await user.getIdTokenResult()
      navigate('/app/dashboard')
    } catch (error) {
      console.error(error)
      setLoadingState(false)
      setErrorMessageState(error)
      setBadLoginState(true)
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit((data) => handleLoginClick(data))
    }
  }

  return (
    <Box
      className={classes.root}
      component="form"
      onSubmit={handleSubmit((data) => handleLoginClick(data))}
    >
      {/* Login form */}
      <Grid container spacing={2}>
        {/* Form title */}
        <Grid item xs={12} className={classes.formTitleGrid}>
          <Typography variant="h3">Sign in to your account</Typography>
        </Grid>
        {/* Email */}
        <Grid item xs={12} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl className={classes.textField}>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="email"
                  placeholder="Email"
                  label="Email"
                  variant="filled"
                />
              </FormControl>
            )}
            name="email"
            control={control}
          />
        </Grid>
        {/* Password */}
        <Grid item xs={12} className={classes.formInputGrid}>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl className={classes.textField}>
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message || ' '}
                  onKeyDown={handleKeyPress}
                  autoComplete="off"
                  type="password"
                  placeholder="Password"
                  label="Password"
                  variant="filled"
                />
              </FormControl>
            )}
            name="password"
            control={control}
          />
        </Grid>
      </Grid>
      {/* Sign in button and forgot password link*/}
      <Grid
        container
        spacing={1}
        className={classes.signInButtonAndForgotPasswordGrid}
      >
        <Grid item xs={12} className={classes.signInButtonGrid}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={LoadingState}
            className={classes.button}
          >
            sign in
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Link to="/auth/forgotpassword">Forgot your password?</Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/auth/register">Not registered? Register here!</Link>
        </Grid>
      </Grid>
      {/* Microsoft login button */}
      <Grid container spacing={1} paddingTop="5%">
        <Grid item xs={12} alignItems={'center'}>
          <Typography textAlign={'center'}>or</Typography>
        </Grid>
        <Grid item xs={12} className={classes.microsoftSignInButtonGrid}>
          <Button
            className={classes.button}
            variant="contained"
            color="white"
            fullWidth
            onClick={(e) => handleMicrosoftLoginClick(e)}
            disabled={LoadingState}
            startIcon={<MicrosfotIcon color="primary" />}
          >
            Microsoft SSO
          </Button>
        </Grid>
      </Grid>
      {/* Alert */}
      <Alert
        open={BadLoginState}
        onClose={() => setBadLoginState(false)}
        message={ErrorMessageState.message}
        severity="error"
      />
    </Box>
  )
}

export default Login
