// Material UI
import { Grid, Typography } from '@mui/material'

// React Query
import { useQuery } from 'react-query'

// Components
import CompanyCard from 'components/CompanyCard'
import Loading from 'components/Loading'

const Dashboard = () => {
  const { isLoading, error, data } = useQuery('repoData', async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations?count=10`
    )
    const jsonResponse = await response.json()
    return jsonResponse
  })

  if (isLoading) return <Loading />
  if (error)
    return (
      <Typography variant="h2">
        {'An error has occurred: ' + error.message}
      </Typography>
    )

  return (
    <>
      <Typography variant="h1">Companies</Typography>
      <div>
        <Grid container>
          {Object.keys(data).map((d) => (
            <CompanyCard companyName={data[d].orgName} key={data[d].orgName} />
          ))}
        </Grid>
      </div>
    </>
  )
}

export default Dashboard
