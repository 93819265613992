import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.neutral,
  },
  outletGrid: {
    minHeight: '100vh',
    padding: '3%',
  },
}))

export default useStyles
