//React
import React, { useEffect, useState, createContext } from 'react'
import PropTypes from 'prop-types'

//Firebase
import { authState } from 'assets/plugins/firebase/auth'

//Loading
import Loading from 'components/Loading'

//Context
const UserContext = createContext()

//Export Provider
export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    const getIdTokenResult = async (user) => {
      try {
        const idTokenResult = await user.getIdTokenResult()
        setCurrentUser({
          ...user,
          claims: idTokenResult.claims,
        })
        setPending(false)
      } catch (error) {
        setCurrentUser(null)
        setPending(false)
      }
    }

    authState((user) => {
      if (!user) {
        setCurrentUser(null)
        setPending(false)
      } else {
        getIdTokenResult(user)
      }
    })
  }, [])

  if (pending) {
    return <Loading />
  }

  return (
    <UserContext.Provider value={{ currentUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext

UserProvider.propTypes = {
  children: PropTypes.any,
}
