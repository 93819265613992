// Yup
import { string as yupString, ref as yupRef } from 'yup'

const passwordRules = yupString()
  .required('Password is Required')
  .min(8, 'Password must be at least 8 characters')
  .max(32, 'Password must be less than 32 characters')
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
    'Password must contain at least one lower case letter, one upper case letter, one number and one special character'
  )

const confirmPasswordRules = yupString()
  .required('Confirm Password is required')
  .oneOf([yupRef('password')], 'Passwords must match')

const phoneRules = yupString()
  .required('Phone is Required')
  .matches(/^[2-9]\d{2}-\d{3}-\d{4}$/, 'Phone must be in format: xxx-xxx-xxxx')

export { passwordRules, confirmPasswordRules, phoneRules }
