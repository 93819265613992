// React
import { useState } from 'react'
import PropTypes from 'prop-types'

// Material
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material'

// Routing
import { useNavigate } from 'react-router-dom'

// Icons
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'

// Firebase
import { logOut } from 'assets/plugins/firebase/auth'

// Components
import Alert from 'components/Alert'

const AppMenu = ({ anchor, open, onClose }) => {
  const [ErrorState, setErrorState] = useState({ status: false, message: '' })

  const navigate = useNavigate()

  const handleLogOutClick = async () => {
    try {
      await logOut()
      navigate('/auth/login')
    } catch (error) {
      setErrorState({ status: true, message: error.message })
    }
  }

  return (
    <Menu
      anchorEl={anchor}
      keepMounted
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={onClose}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogOutClick}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() => setErrorState({ status: false, message: '' })}
      />
    </Menu>
  )
}

export default AppMenu

AppMenu.propTypes = {
  anchor: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
