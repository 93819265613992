import { Box, Typography, IconButton } from '@mui/material'

import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YoutubeIcon from '@mui/icons-material/YouTube'
import EmailIcon from '@mui/icons-material/Email'
import LanguageIcon from '@mui/icons-material/Language'

const AppFooter = () => {
  return (
    <Box
      bgcolor="primary.main"
      display="flex"
      alignItems="center"
      padding="0 1%"
    >
      <Typography variant="body2" color="white.main">
        Daugherty Business Solutions © 2022
      </Typography>
      <IconButton href="https://www.daugherty.com/" target="_blank">
        <LanguageIcon color="white" />
      </IconButton>
      <IconButton
        href="https://www.instagram.com/daughertyphotos"
        target="_blank"
      >
        <InstagramIcon color="white" />
      </IconButton>
      <IconButton href="https://www.facebook.com/dbs1985" target="_blank">
        <FacebookIcon color="white" />
      </IconButton>
      <IconButton href="https://www.youtube.com/c/Daugherty" target="_blank">
        <YoutubeIcon color="white" />
      </IconButton>
      <IconButton href="mailto:datatrust@daugherty.com">
        <EmailIcon color="white" />
      </IconButton>
    </Box>
  )
}

export default AppFooter
