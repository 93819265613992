import { useContext } from 'react'
import UserContext from 'assets/plugins/contexts/userContext'

const useUser = () => {
  const { currentUser } = useContext(UserContext)

  return currentUser
}

export default useUser
