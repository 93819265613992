import { auth } from './index'
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  SAMLAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  // connectAuthEmulator,
} from 'firebase/auth'

// if (process.env.REACT_APP_ENV !== 'production') {
//   connectAuthEmulator(auth, 'http://localhost:9099')
// }

const azureAdProvider = new SAMLAuthProvider('saml.azure-active-directory')

export const emailLogin = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
}

export const userRegister = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
}

export const authState = (callback) => {
  return onAuthStateChanged(auth, callback)
}

export const logOut = () => {
  return signOut(auth)
}

export const passwordReset = (email) => {
  return sendPasswordResetEmail(auth, email)
}

export const signInWithAD = () => {
  return signInWithPopup(auth, azureAdProvider)
}
