//Material UI
import { Box, CircularProgress, Typography } from '@mui/material'

const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <Typography variant="h1">Loading</Typography>
      <CircularProgress color="primary" size={'7rem'} />
    </Box>
  )
}

export default Loading
