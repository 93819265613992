import React from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

const Company = () => {
  const { company } = useParams()

  return <Typography variant="h3">{company}</Typography>
}

export default Company
